import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Asset1 from './assets/asset_1.png'
import ZampLogo from './assets/zamp_logo_1.png'
import MacbookPro from './assets/macbook_pro.jpg'
import SoundWavesImage from './assets/sound_waves.png'
import ArrowImage from './assets/arrow.png'
import LinkedinImage from './assets/social_linkedin_white_transparent.png'
import MailImage from './assets/social_mail_white_transparent.png'
import EllipseImage from './assets/radial_circle.png'
import USDCSymbol from './assets/usdc_symbol.png'
import ArrowTopRight from './assets/arrow_top_right.png'
import { Col, Row } from 'reactstrap';
// import { useEffect, useRef, useState } from 'react';
import SlideShowItem from './components/slideshowitem';

function App() {
  var slideState = false
  // var [slideState, setSlideState] = useState(false)
  // var timeOut = useRef(null)
  // useEffect(() => {
  //   timeOut.current = setTimeout(() => setSlideState(!slideState), 6000)
  // }, [slideState])
  // const setSlideStateManually = () => {
  //   clearTimeout(timeOut.current)
  //   setSlideState(!slideState)
  // }
  var data = [
    {
      text: <>Global banking.<br/>Simplified.</>,
    },{
      text: <>Earn upto 4% yield<br/>on your balance</>,
    }
  ]
  return <div className='z-container-main'>
    <div className='absolute-images'>
      <img className='absolute-image-1' src={EllipseImage} alt='background 1'/>
    </div>
    <div className='z-full'>
      <div className='z-container-default'>
        <div className='z-head-logo-container'>
          <img className='z-head-logo' src={ZampLogo} alt='Zamp Logo'/>
        </div>
      </div>
      <div className='z-container-default'>
        <div className='z-slide-show-container'>
          <div className='z-slide-show-main'>
            <div className='z-slide-show' style={{transform: `translate3d(${-(slideState?0:1) * 100}%, 0, 0)`}}>
              <SlideShowItem text={data[0].text} image={MacbookPro}/>
              <SlideShowItem text={data[1].text} image={MacbookPro}/>
            </div>
          </div>
          {/* <div className='z-slide-show-pointers'>
            <span onClick={setSlideStateManually} style={{background: slideState?"#2546F5":"#898989"}} className='z-slide-show-pointer'/>
            <span onClick={setSlideStateManually} style={{background: slideState?"#898989":"#2546F5"}} className='z-slide-show-pointer'/>
          </div> */}
        </div>
      </div>
      <div className='z-container-default'>
        <div className='z-solution-container'>
          <div className='z-solution-header-container'>
            <div>Zamp is the only banking solution you will ever need</div>
          </div>
          <div className='z-solution-tabs-container'>
            <Row className='z-solution-tabs-content no-gutters' noGutters>
              <Col sm='4' className='z-solution-tab-col'>
                <div className='z-solution-tab-content'>
                  <div className='z-solution-tab-card'>
                    <div className='z-solution-account-balance-content'>
                      <div className='z-solution-account-balance-header'>Total account balance</div>
                      <div className='z-solution-account-balance-amount'>USD 32,220,112</div>
                      <div className='z-solution-account-balance-header'>among Dubai, US & Singapore</div>
                    </div>
                    <img className='z-solution-account-balance-graph-image' src={SoundWavesImage} alt='Sound Waves'/>
                  </div>
                  <div className='z-solutions-tab-summary-container'>
                    <div className='z-solutions-tab-summary-header'>Truly global banking</div>
                    <div className='z-solutions-tab-summary-content'>Manage all entities and bank accounts in one place</div>
                  </div>
                </div>
              </Col>
              <Col sm='4' className='z-solution-tab-col'>
                <div className='z-solution-tab-content'>
                  <div className='z-solution-tab-card'>
                    <div className='z-solution-send-money-button-container-3'>
                      <div className='z-solution-send-money-button-container-2'>
                        <div className='z-solution-send-money-button-container-1'>
                          <div className='z-solution-send-money-button-container-0'>
                            <div><img className='z-solution-send-money-icon' src={ArrowTopRight} alt=''/> Send money</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='z-solutions-tab-summary-container'>
                    <div className='z-solutions-tab-summary-header'>Super fast global money transfers</div>
                    <div className='z-solutions-tab-summary-content'>Send money anywhere across the world be it banks or crypto wallets</div>
                  </div>
                </div>
              </Col>
              <Col sm='4' className='z-solution-tab-col'>
                <div className='z-solution-tab-content'>
                  <div className='z-solution-tab-card'>
                    <div>
                      <div className='z-solution-investment-item'>
                        <div><img className='z-solution-investment-icon' src={USDCSymbol} alt="USDC"/>+UDSC 2122.212</div>
                      </div>
                    </div>
                    <div>
                      <div className='z-solution-investment-item'>
                        <div><img className='z-solution-investment-icon' src={USDCSymbol} alt="USDC"/>+UDSC 312.212</div>
                      </div>
                    </div>
                  </div>
                  <div className='z-solutions-tab-summary-container'>
                    <div className='z-solutions-tab-summary-header'>Transaction and account insights</div>
                    <div className='z-solutions-tab-summary-content'>Stay on top of your PNL in the most effective way that never existed</div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className='z-self-article-container'>
            <a className='z-link' href="https://techcrunch.com/2022/05/23/dara-khosrowshahi-and-marcelo-claure-back-former-sequoia-india-partner-amit-jains-startup/?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAB4HvgGhzp-FgtvSehJ14DIAT_zB2wDTkl63V9W0WfwfNfYhHlredZUb4Fhe4ZJ1vXR2lIKrqr8H3TljUOA6qZ_mYzcn4hOZy2gbdWlJXwrbcxdmxS2anqcacfU4oViAgB6ccLoJZX7TIM7E8XHbtmvLxCJNN_zdUT39IWtbPIlh" target="_blank" rel="noreferrer"><div className='z-self-article'>
              <div><img src={Asset1} alt='Asset 1' width={40}/></div>
              <div className='z-self-article-main'>Ex-MD Sequoia, Exec-Uber APAC Head Amit Jain’s New Venture Raises<br/>$25 mn in Seed Round</div>
              <div className='z-self-article-link'>Read more on tech crunch <img className='z-inline-image' src={ArrowImage} alt='Arrow Right'/></div>
            </div></a>
          </div>
        </div>
      </div>
      <div className='z-container-default'>
        <div className='z-doordarsh-article-container'>
          <div className='z-doordarsh-article-content'>
            <div className='z-doordarsh-article-main'>
              &#8216;I&lsquo;m very excited for the next generation of banking that amit and team are tirelessly creating&#8217;
            </div>
            <div className='z-doordarsh-article-writer'>
              Saurabh Ariyan
            </div>
          </div>
        </div>
      </div>
      <div className='z-container-default'>
        <div className='z-footer-container'>
          <Row>
            <Col sm={7}>
              <div className='z-footer-summary-container'>
                <div className='z-footer-summary-content'>1&nbsp;<b>Zamp</b> is a financial platform, not a bank. Banking services provided and debit cards issued by Cash's bank partners.</div>
                <div className='z-footer-summary-content'>2&nbsp;Brokerage services by Zamp Investing LLC, subsidiary of Block, Inc., member FINRA/SIPC.See our BrokerCheck. Bitcoin services provided by Block, Inc. Investing involves risk; you may lose money.</div> 
                <div className='z-footer-summary-content'>3&nbsp;<b>Zamp</b> instantly reimburses ATM fees, including ATM operator fees, for 3 ATM withdrawals per month (up to $7.00 per withdrawal)</div>
              </div>
            </Col>
            <Col sm={5}>
              <div className='z-footer-contact-container'>
                <div>
                  <img className='z-footer-logo' src={ZampLogo} alt='Zamp Logo'/>
                </div>
                <br/>
                <div className='z-footer-contact-header'>Stay in touch</div>
                <div>
                  <a href='https://www.linkedin.com/company/zampfintech/mycompany/' rel='noreferrer' target='_blank'><img className='z-footer-social-handle-image' src={LinkedinImage} alt='Twitter'/></a>
                  <a href='mailto:saurabh@zamp.finance'><img className='z-footer-social-handle-image' src={MailImage} alt='E-Mail'/></a>
                </div>
              </div>
            </Col>
          </Row>
          <hr className='footer-hr'/>
          <div className='c-footer-special-links'>
            <span className='c-footer-special-link'>&copy; Zamp technologies, Inc.</span>
            <span className='c-footer-special-link'>Privacy policy</span>
            <span className='c-footer-special-link'>Terms of use</span>
            <span className='c-footer-special-link'>Responsible disclosures</span>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default App;
