const SlideShowItem = (props) => {
    return <div className='z-slide-show-slide' id='slide1'>
      <div className='z-full'>
        <div className='z-banking-header'>{props.text}</div>
        <div className='z-banking-button-container'>
          <div className='z-button-round-blue'>I&#8217;m interested </div>
        </div>
      </div>
      <div className='z-banking-highlight-image-container'>
        <div>
          <img className='z-banking-highlight-image' src={props.image} alt='I Pad Pro 12'/>
        </div>
      </div>
    </div>
}
export default SlideShowItem